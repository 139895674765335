import React, { useEffect, useState } from "react";
import { getBuyBricsApi, getRentApi } from "../../api";
import ReactPaginate from "react-paginate";
import toast from "toastr";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { formatDate, currancyFormat } from '../../utils/index';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const PurchasedPage = () => {
  toast.options = { preventDuplicates: true };
  const params = new URLSearchParams(useLocation().search).get("id");
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("transaction");
  const [properties, setProperties] = useState([]);
  const [rentData, setRentData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalRentCount, setTotalRentCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [loader, setLoader] = useState(false);
  const [totalRentEarned, setTotalRentEarned] = useState(0);

  useEffect(() => {
    fetchProperties(1);
  }, []);

  const fetchProperties = async (pageNumber) => {
    try {
      setLoader(true);
      const response = await getBuyBricsApi({ propertyId: params, page: pageNumber });
      if (response?.status === 200) {
        setLoader(false);
        setProperties(response.data?.data);
        setTotalCount(response.data?.count);
        setTotalRentEarned(response.data.totalRentEarned);
      } else if (response?.data?.error_description?.statusCode === 401) {
        toast.error(response?.data?.error_description?.message);
        setLoader(false);
        localStorage.clear();
        navigate("/login");
      } else {
        setLoader(false);
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchRentData = async (pageNumber) => {
    try {
      setLoader(true);
      const response = await getRentApi({propertyId: params, page: pageNumber });
      if (response?.status === 200) {
        setLoader(false);
        setRentData(response.data?.data);
        setTotalRentCount(response.data?.count);
      } else if (response?.data?.error_description?.statusCode === 401) {
        toast.error(response?.data?.error_description?.message);
        setLoader(false);
        localStorage.clear();
        navigate("/login");
      } else {
        setLoader(false);
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  const handlePageClick = (selectedPage) => {
    const page = selectedPage.selected + 1;
    if (activeTab === "transaction") {
      fetchProperties(page);
    } else {
      fetchRentData(page);
    }
    setCurrentPage(page);
  };

  const pageCount = activeTab === "transaction"
    ? Math.ceil(totalCount / itemsPerPage)
    : Math.ceil(totalRentCount / itemsPerPage);

  return (
    <div className="section-properties container">
      {/* Top Navigation Section */}
      <div className="w-full flex justify-between items-center">
        <p
          className="font-bold text-primary text-xl cursor-pointer my-4"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} /> Purchased Transaction
        </p>
      </div>

      {/* Tabs */}
      <div className="tabs mb-4 d-flex">
        <button
          className={`tab-button ${activeTab === "transaction" ? "active-tab" : ""}`}
          onClick={() => setActiveTab("transaction")}
        >
          Transaction
        </button>
        <button
          className={`tab-button ${activeTab === "rent" ? "active-tab" : ""}`}
          onClick={() => {
            setActiveTab("rent");
            fetchRentData(1);
          }}
        >
          Rent
        </button>
      </div>

      {loader ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-12">
            {activeTab === "transaction" ? (
              // Transaction Tab Content
              properties && properties.length > 0 ? (
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Property Name</th>
                      <th>Purchased Brics</th>
                      <th>Amount</th>
                      <th>Purchased Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {properties.map((val) => (
                      <tr key={val.id}>
                        <td>
                          <a href={`/property-details?id=${val?.property?.id}`}>
                            {val.property?.name}
                          </a>
                        </td>
                        <td>{val?.brics}</td>
                        <td>{currancyFormat(val?.amount)}</td>
                        <td>{formatDate(val?.createdAt)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="col-12 text-center">No transactions found.</div>
              )
            ) : (
              // Rent Tab Content
              rentData && rentData.length > 0 ? (
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Property Name</th>
                      <th>Rent Month</th>
                      <th>Rent Year</th>
                      <th>Amount</th>
                      <th>Rent Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rentData.map((val) => (
                      <tr key={val.id}>
                        <td>{val.property?.name}</td>
                        <td>{val.month}</td>
                        <td>{val.year}</td>
                        <td>{currancyFormat(val?.amount)}</td>
                        <td>{formatDate(val?.createdAt)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="col-12 text-center">No rent data found.</div>
              )
            )}

            <ReactPaginate
              breakLabel="..."
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              className={"pagination justify-content-center pt-4"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              activeClassName={"active"}
              onPageChange={handlePageClick}
              previousLabel={"Previous"}
              nextLabel={"Next"}
              previousClassName={"page-item"}
              nextClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PurchasedPage;
