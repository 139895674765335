import { faRupee } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import { getGraphDetailsApi } from "../api";

const DummyGraph = ({ id }) => {
  const [data, setData] = useState([]);
  const [graphWidth, setGraphWidth] = useState(700); // Default width
  const parentRef = useRef(null);

  useEffect(() => {
    if (id) graphDetails();
  }, [id]);

  useEffect(() => {
    // Adjust width dynamically based on parent element
    const handleResize = () => {
      if (parentRef.current) {
        setGraphWidth(parentRef.current.offsetWidth);
      }
    };
    handleResize(); // Call on mount
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const graphDetails = async () => {
    try {
      const res = await getGraphDetailsApi(id);
      if (res?.status === 200) {
        const data = res.data.data || [];
        data.sort((a, b) => a.year - b.year);
        setData(data);
      }
    } catch (err) {
      throw err;
    }
  };

  const height = 250; // Fixed height
  const padding = 40;

  // Calculate scales
  const xScale = (graphWidth - padding * 2) / (data.length - 1);
  const yScale =
    (height - padding * 2) /
    (Math.max(...data.map((d) => d.price)) -
      Math.min(...data.map((d) => d.price)));

  // Generate wave path using Bézier curves
  const pathData = data
    .map((d, i) => {
      const x = i * xScale + padding;
      const y =
        height - (d.price - Math.min(...data.map((d) => d.price))) * yScale - padding;
      return { x, y };
    })
    .reduce((acc, point, i, arr) => {
      if (i === 0) {
        return `M${point.x},${point.y}`;
      }
      const prevPoint = arr[i - 1];
      const cp1x = prevPoint.x + (point.x - prevPoint.x) / 2;
      const cp1y = prevPoint.y;
      const cp2x = point.x - (point.x - prevPoint.x) / 2;
      const cp2y = point.y;
      return `${acc} C${cp1x},${cp1y} ${cp2x},${cp2y} ${point.x},${point.y}`;
    }, "");

  const areaPathData = `${pathData} L${graphWidth - padding},${height - padding} L${padding},${height - padding} Z`;

  const numTicks = 5;

  return (
    <div ref={parentRef} className="w-full">
      {data && data.length > 0 && (
        <>
          <p className="text-md text-[#005555] mt-4 font-semibold">AVG PROPERTY RATE</p>
          <svg
            viewBox={`0 0 ${graphWidth} ${height}`}
            width="100%"
            height={height}
            className="-mt-4"
          >
            {/* X and Y axes */}
            <line
              x1={padding}
              y1={height - padding}
              x2={graphWidth - padding}
              y2={height - padding}
              stroke="lightgray"
            />
            <line
              x1={padding}
              y1={padding}
              x2={padding}
              y2={height - padding}
              stroke="lightgray"
            />

            {/* Shaded area */}
            <path d={areaPathData} fill="rgba(0, 128, 0, 0.2)" />

            {/* Data line */}
            <path
              d={pathData}
              fill="none"
              stroke="green"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />

            {/* X-axis labels */}
            {data.map((d, i) => (
              <text
                key={i}
                x={i * xScale + padding}
                y={height - padding / 2}
                textAnchor="middle"
                fontSize="8"
              >
                {d.year}
              </text>
            ))}

            {/* Y-axis labels and grid lines */}
            {[...Array(numTicks)].map((_, tick) => {
              const yPosition =
                height - (tick * (height - padding * 2)) / (numTicks - 1) - padding;
              const priceValue = Math.round(
                ((tick *
                  (Math.max(...data.map((d) => d.price)) -
                    Math.min(...data.map((d) => d.price)))) /
                  (numTicks - 1) +
                  Math.min(...data.map((d) => d.price))) /
                  1000
              );

              return (
                <React.Fragment key={tick}>
                  <text
                    x={padding / 2}
                    y={yPosition}
                    textAnchor="middle"
                    fontSize="9"
                    dy="0.3em"
                  >
                    <FontAwesomeIcon icon={faRupee} /> {priceValue && `${priceValue}k`}
                  </text>
                  <line
                    x1={padding}
                    y1={yPosition}
                    x2={graphWidth - padding}
                    y2={yPosition}
                    stroke="lightgray"
                    strokeDasharray="4 2"
                  />
                </React.Fragment>
              );
            })}
          </svg>
        </>
      )}
    </div>
  );
};

export default DummyGraph;
