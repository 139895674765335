import { useEffect, useState } from "react";
import { getProfileApi, getPropertyDetailsApi } from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "toastr";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AboutLocation from "../../components/Modals/aboutLocationModal";
import {
  faArrowLeft,
  faCar,
  faChild,
  faDumbbell,
  faShieldAlt,
  faSwimmer,
  faTree,
  faDice,
  faBatteryFull,
  faHouseUser,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DummyGraph from "../../components/Graph";
import BuyBricsModal from "../../components/Modals/buyBricsModal";
import ImagePreview from "../../components/imagePreview";
import { formatDate, currancyFormat, getFileIcon } from "../../utils/index";

const AMENITY_ICONS = {
  "Children's Play Area": faChild,
  "Swimming Pool": faSwimmer,
  "Indoor Games": faDice,
  "Landscaping & Tree Planting": faTree,
  "24x7 Security": faShieldAlt,
  "Power Backup": faBatteryFull,
  Gymnasium: faDumbbell,
  "Club House": faHouseUser,
};

const PropertyDetailsPage = () => {
  toast.options = { preventDuplicates: true };
  const navigate = useNavigate();
  const params = new URLSearchParams(useLocation().search).get("id");
  const [propertyDetails, setPropertyDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [showReadMe, setShowReadMe] = useState(false);
  const [buyShow, setBuyShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [checked, setChecked] = useState(false);

  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen2, setIsOpen2] = useState(true);
  const [isOpen3, setIsOpen3] = useState(true);
  const [isOpen4, setIsOpen4] = useState(true);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const openModal = (image) => {
    setCurrentImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImage("");
  };

  const handleBuyClose = () => {
    setBuyShow(false);
  };

  useEffect(() => {
    getPropertyDetails();
    getProfile();
  }, []);

  const settings = {
    customPaging: function (i) {
      return (
        <a>
          <img src={`${propertyDetails.images[i]}`} />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-dots-custom slick-thumb",
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const getPropertyDetails = async () => {
    try {
      if (params) {
        setLoader(true);
        const res = await getPropertyDetailsApi(params);
        if (res?.status == 200) {
          setLoader(false);
          setPropertyDetails(res?.data?.data);
        } else {
          setLoader(false);
        }
      }
    } catch (err) {
      setLoader(false);
    }
  };

  const getProfile = async () => {
    if (localStorage.getItem("token")) {
      try {
        const res = await getProfileApi();
        if (res?.status == 200) {
          setUserDetails(res?.data?.data);
        } else {
          setLoader(false);
        }
      } catch (err) {
        setLoader(false);
      }
    }
  };

  const handleReadMore = () => {
    setShowReadMe(true);
  };

  const onClose = () => {
    setShowReadMe(false);
  };

  const isDateBeforeToday = (date) => {
    const someDate = new Date(date);
    const today = new Date();
    someDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    // Compare the provided date to today's date
    return someDate <= today;
  };

  const mapUrl = `https://www.google.com/maps?q=${propertyDetails?.lat},${propertyDetails.lng}&hl=es;z=10&output=embed`;

  return (
    <>
      {loader ? (
        <div className="d-flex justify-content-center text-primary mb-12 mt-12">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="w-52">
            <p
              className=" font-bold text-primary text-xl mb-4 cursor-pointer "
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Back
            </p>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-8 col-md-8 mb-4 mb-8">
              {propertyDetails?.images &&
              propertyDetails?.images.length <= 1 ? (
                <div className="image-container">
                  <img
                    src={propertyDetails?.images[0]}
                    alt={`Image`}
                    className="image-property"
                    onClick={() => openModal(propertyDetails?.images[0])}
                  />
                </div>
              ) : (
                <Slider {...settings}>
                  {propertyDetails?.images?.map((img, index) => (
                    <div className="image-container" key={index}>
                      <img
                        src={img}
                        alt={`Image ${index + 1}`}
                        className="image-property"
                        onClick={() => openModal(img)}
                      />
                    </div>
                  ))}
                </Slider>
              )}

              {/* Detailed Information Section */}
              <div className="bg-white border rounded-sm p-6 w-full my-2">
                <h2 className="text-lg font-semibold text-gray-700 mb-4">
                  Bric Details
                </h2>
                <div className="grid grid-cols-3 gap-4">
                  {[
                    {
                      label: "Total Brics Price",
                      value: propertyDetails?.actualPropertyCost,
                    },
                    {
                      label: "Total Brics",
                      value: propertyDetails?.totalBrics,
                    },
                    {
                      label: "Available Brics",
                      value: propertyDetails?.availableBrics,
                    },
                    {
                      label: "Each Bric Price",
                      value: propertyDetails?.eachBricCost,
                    },
                    {
                      label: "Rent Yield Per Month",
                      value: propertyDetails?.rentPerMonth,
                    },
                  ].map((item, index) => (
                    <div key={index}>
                      <span className="font-medium text-gray-700">
                        {item.label}
                      </span>
                      <p className="font-semibold text-[#005555]">
                        {currancyFormat(item.value)}
                      </p>
                    </div>
                  ))}
                </div>
              </div>

              <div className="max-w-full mx-auto mt-10 space-y-4">
                {/* Accordion 1 */}
                <div className="border border-gray-300 rounded-sm">
                  <button
                    onClick={() => setIsOpen1(!isOpen1)}
                    className="w-full flex justify-between items-center px-4 py-6 text-lg font-bold text-gray-800 bg-gray-100 hover:bg-gray-200 focus:outline-none"
                  >
                    <span>About Property</span>
                    <svg
                      className={`w-3 h-3 shrink-0 ${
                        isOpen1 ? "" : "rotate-180"
                      }`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5 5 1 1 5"
                      />
                    </svg>
                  </button>
                  {isOpen1 && (
                    <div className="px-4 py-2 bg-white text-gray-700 border-t border-gray-300">
                      <span className="font-semibold">Name:</span>
                      <p className="text-primary font-medium">
                        {propertyDetails.name}
                      </p>

                      <span className="font-semibold">Property Location:</span>
                      <p className="text-primary font-medium">
                        {propertyDetails.location || "-"}
                      </p>

                      <span className="font-semibold">About the Location:</span>
                      <p className="text-primary font-medium">
                        {propertyDetails?.aboutLocation}
                      </p>

                      <span className="font-semibold">About Property:</span>
                      <p className="text-primary font-medium">
                        {propertyDetails?.aboutProperty}
                      </p>

                      <span className="font-semibold">Layout Area:</span>
                      <p className="text-primary font-medium">
                        {propertyDetails?.area}
                      </p>

                      <span className="font-semibold">Launch Date:</span>
                      <p className="text-primary font-medium">
                        {formatDate(propertyDetails.launchingDate)}
                      </p>

                      <span className="font-semibold">Maturity Date:</span>
                      <p className="text-primary font-medium">
                        {formatDate(propertyDetails.maturityDate)}
                      </p>
                    </div>
                  )}
                </div>

                {/* Accordion 2 */}
                <div className="border border-gray-300 rounded-sm">
                  <button
                    onClick={() => setIsOpen2(!isOpen2)}
                    className="w-full flex justify-between items-center px-4 py-6 text-lg font-bold text-gray-800 bg-gray-100 hover:bg-gray-200 focus:outline-none"
                  >
                    <span>Amenities</span>
                    <svg
                      className={`w-3 h-3 shrink-0 ${
                        isOpen2 ? "" : "rotate-180"
                      }`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5 5 1 1 5"
                      />
                    </svg>
                  </button>
                  {isOpen2 && (
                    <div className="p-4 bg-white text-gray-700 border-t text-gray-600 grid grid-cols-1 gap-4">
                      {propertyDetails?.amenities?.length > 0 ? (
                        propertyDetails.amenities.map((val, index) => {
                          const Icon = AMENITY_ICONS[val];
                          return (
                            <div className="" key={index}>
                              {Icon && (
                                <FontAwesomeIcon
                                  className="w-4 h-4 mr-2"
                                  icon={Icon}
                                />
                              )}{" "}
                              {val}
                            </div>
                          );
                        })
                      ) : (
                        <span className="text-gray-600 ml-4 whitespace-nowrap">
                          N/A
                        </span>
                      )}
                    </div>
                  )}
                </div>

                {/* Accordion 3 */}
                <div className="border border-gray-300 rounded-sm">
                  <button
                    onClick={() => setIsOpen3(!isOpen3)}
                    className="w-full flex justify-between items-center px-4 py-6 text-lg font-bold text-gray-800 bg-gray-100 hover:bg-gray-200 focus:outline-none"
                  >
                    <span>Documents</span>
                    <svg
                      className={`w-3 h-3 shrink-0 ${
                        isOpen3 ? "" : "rotate-180"
                      }`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5 5 1 1 5"
                      />
                    </svg>
                  </button>
                  {isOpen3 && (
                    <div className="p-4 bg-white text-gray-700 border-t border-gray-300">
                      {propertyDetails?.documentsLink &&
                      propertyDetails.documentsLink.length > 0 ? (
                        <div className="grid grid-cols-1 gap-4">
                          {propertyDetails?.documentsLink.map((link, index) => (
                            <div
                              key={index}
                              className="flex items-center justify-between p-4 border rounded-md shadow-sm bg-[#F8F8F8] hover:shadow-md dark:bg-gray-800 dark:border-gray-700"
                            >
                              <div className="flex items-center gap-3">
                                {/* File Icon */}
                                <div>{getFileIcon(link)}</div>

                                {/* File Name */}
                                <div className="text-sm font-medium text-gray-800 dark:text-gray-200 truncate">
                                  {link.split("/").pop()}
                                </div>
                              </div>

                              {/* Download Button */}
                              <a
                                href={link}
                                download
                                className="flex items-center gap-2 text-[#005555] hover:text-[#008080] dark:text-blue-400 dark:hover:text-blue-300"
                              >
                                <FontAwesomeIcon
                                  icon={faDownload}
                                  className="w-6 h-6"
                                />
                              </a>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p className="text-gray-500 dark:text-gray-400">
                          No documents available
                        </p>
                      )}
                    </div>
                  )}
                </div>

                {/* Accordion 4 */}
                <div className="border border-gray-300 rounded-sm">
                  <button
                    onClick={() => setIsOpen4(!isOpen4)}
                    className="w-full flex justify-between items-center px-4 py-6 text-lg font-bold text-gray-800 bg-gray-100 hover:bg-gray-200 focus:outline-none"
                  >
                    <span>Price Summary</span>
                    <svg
                      className={`w-3 h-3 shrink-0 ${
                        isOpen4 ? "" : "rotate-180"
                      }`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5 5 1 1 5"
                      />
                    </svg>
                  </button>
                  {isOpen4 && (
                    <div className="px-4 py-2 bg-white text-gray-700 border-t border-gray-300">
                      <DummyGraph id={propertyDetails?.id} />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-4 col-md-4 mb-4 mb-8">
              <div className="p-6 border rounded-md features-1 transition-transform transform">
                <h2
                  className="text-lg font-semibold text-gray-800 mb-4 w-85"
                  style={{ width: "85%" }}
                >
                  Property Details
                </h2>
                {[
                  // {
                  //   label: "Total Brics Price",
                  //   value: propertyDetails?.actualPropertyCost,
                  // },
                  // { label: "Total Brics", value: propertyDetails?.totalBrics },
                  {
                    label: "Available Brics",
                    value: propertyDetails?.availableBrics,
                  },
                  {
                    label: "Each Bric Price",
                    value: propertyDetails?.eachBricCost,
                  },
                  // {
                  //   label: "Rent Yield Per Month",
                  //   value: propertyDetails?.rentPerMonth,
                  // },
                ].map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center border-b border-gray-200 py-2 last:border-b-0 hover:bg-gray-50 transition duration-200"
                  >
                    <span className="font-medium text-gray-700">
                      {item.label}
                    </span>
                    <span className="font-semibold text-gray-900">
                      {currancyFormat(item.value)}
                    </span>
                  </div>
                ))}

                {/* Progress bar */}
                <div className="mt-4">
                  <div className="flex justify-between mb-1">
                    <span className="text-sm font-medium text-gray-700">
                      Brics Sold
                    </span>
                    <span className="text-sm font-medium text-gray-700">
                      {propertyDetails?.totalBrics -
                        propertyDetails?.availableBrics}
                      /{propertyDetails?.totalBrics}
                    </span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-4">
                    <div
                      className="bg-green-500 h-4 rounded-full"
                      style={{
                        width: `${
                          ((propertyDetails?.totalBrics -
                            propertyDetails?.availableBrics) /
                            propertyDetails?.totalBrics) *
                          100
                        }%`,
                      }}
                    ></div>
                  </div>
                </div>
                <div className="mt-6 w-full flex flex-col items-center">
                  {propertyDetails?.availableBrics > 0 &&
                    isDateBeforeToday(propertyDetails.launchingDate) && (
                      <button
                        onClick={() => {
                          if (localStorage.getItem("token")) {
                            // userDetails?.nominee?.id &&
                            if (
                              userDetails?.document?.id &&
                              userDetails?.docStatus != "rejected"
                            ) {
                              if (!userDetails?.isEmailVerify) {
                                toast.error("Please verify your email first!");
                                navigate("/profile");
                              } else {
                                if (userDetails?.verify) {
                                  setBuyShow(true);
                                } else {
                                  toast.error(
                                    "Please wait, your profile is currently under review!"
                                  );
                                }
                              }
                            } else if (
                              userDetails?.document?.id &&
                              userDetails?.docStatus == "rejected"
                            ) {
                              toast.error(
                                "Your documents are rejected you need to re-upload your docs!"
                              );
                              navigate("/profile");
                            } else {
                              toast.error(
                                "Please add your nominee details and documents first!"
                              );
                              navigate("/profile");
                            }
                          } else {
                            navigate("/login");
                          }
                        }}
                        class="ml-2 relative group overflow-hidden border-2 border-[#005555] rounded-lg px-4 py-2 w-full text-[#005555] font-semibold outlined animate-pulse zoom-effect bg-[#005555] text-white"                      >
                        Buy Brics
                      </button>
                    )}
                </div>
              </div>

              <div className="mt-6 w-full flex flex-col items-center">
                <div className="mb-4 w-full">
                  <iframe
                    src={mapUrl}
                    height="320"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    className="rounded-lg w-full"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <BuyBricsModal
        getPropertyDetails={getPropertyDetails}
        show={buyShow}
        onClose={handleBuyClose}
        propertyDetails={propertyDetails}
        checked={checked}
        handleCheckboxChange={handleCheckboxChange}
      ></BuyBricsModal>

      <AboutLocation show={showReadMe} onClose={onClose}>
        <h3 className="text-xl text-primary font-bold mb-2">
          About the Location:
        </h3>
        <p className="text-gray-600">{propertyDetails?.aboutLocation}</p>
      </AboutLocation>
      <ImagePreview
        image={currentImage}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      />
    </>
  );
};

export default PropertyDetailsPage;
