import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "toastr";
import {
  addDocApi,
  editProfileApi,
  getProfileApi,
  ResendOTPApi,
  updateDocApi,
  verifyOtpApi,
} from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faUpload } from "@fortawesome/free-solid-svg-icons";
import AddYourDocModal from "../../components/Modals/addYourDocsModal";
import VerifyEmailModal from "../../components/Modals/verifyEmail";
import OTPInput, { ResendOTP } from "otp-input-react";
import Loader from "../../components/Loader";

const ProfilePage = () => {
  toast.options = { preventDuplicates: true };
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const myAdharInputRef = useRef(null);
  const myAdharBackInputRef = useRef(null);
  const myPanInputRef = useRef(null);
  const [data, setData] = useState({});
  const [nomineeId, setNomineeId] = useState("");
  const [errors, setErrors] = useState({});
  const [showOTP, setShowOTP] = useState(false);
  const [showDoc, setShowDoc] = useState(false);
  const [adharUrl, setAdharUrl] = useState(null);
  const [adharUrlBack, setAdharUrlBack] = useState(null);
  const [panUrl, setPanUrl] = useState(null);
  const [aPreview, setAPreview] = useState(null);
  const [aPreviewBack, setAPreviewBack] = useState(null);
  const [panPreview, setPanPreview] = useState(null);
  const [errorDoc, setErrorsDoc] = useState({});
  const [loader, setLoader] = useState(false);
  const [docDetails, setDocDetails] = useState({});
  const [docId, setDocId] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [isEmailVerify, setisEmailVerify] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getProfile();
  }, []);

  const validateA = () => {
    const numPattern = /^\d+$/;
    const panCardRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

    let newErrors = {};
    if (!docDetails.aadharcardNo) {
      newErrors.aadharcardNo = " Aadhar Card No. is required";
    } else if (docDetails.aadharcardNo.length != 12) {
      newErrors.aadharcardNo = " Aadhar Card Should have 12 digits no. only";
    } else if (
      docDetails.aadharcardNo &&
      !numPattern.test(docDetails.aadharcardNo)
    ) {
      newErrors.aadharcardNo = " Aadhar Card Should have 12 digits no. only";
    } else if (!docDetails.panCardNo) {
      newErrors.panCardNo = " Pan Card No. is required";
    } else if (
      docDetails.panCardNo &&
      !panCardRegex.test(docDetails.panCardNo)
    ) {
      newErrors.panCardNo = "Valid Pan Card No. is required";
    }
    setErrorsDoc(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleDoxClose = () => {
    setShowDoc(false);
  };

  const handleOTPClose = () => {
    setShowOTP(false);
  };

  const handleAClick = () => {
    myAdharInputRef.current.click();
  };

  const handleFileBAdharClick = () => {
    myAdharBackInputRef.current.click();
  };

  const handlePClick = () => {
    myPanInputRef.current.click();
  };

  const getProfile = async () => {
    try {
      setLoader(true);
      const res = await getProfileApi();
      if (res?.status == 200) {
        setLoader(false);
        setData({
          ...data,
          firstName: res?.data?.data?.firstName,
          lastName: res?.data?.data?.lastName,
          email: res?.data?.data?.email,
          mobileNumber: res?.data?.data?.mobile,
        });
        // setPanPreview(res?.data?.data?.document?.panCard);
        // setAdharUrl(res?.data?.data?.document?.aadharcard);
        // setAdharUrlBack(res?.data?.data?.document?.aadharcardBack);
        // setPanUrl(res?.data?.data?.document?.panCard);
        // setAPreview(res?.data?.data?.document?.aadharcard);
        // setAPreviewBack(res?.data?.data?.document?.aadharcardBack);
        setUserInfo(res?.data?.data);
        setisEmailVerify(res?.data?.data?.isEmailVerify);
        setDocDetails(res?.data?.data?.document);
        setNomineeId(res?.data?.data?.nominee?.id);
        setDocId(res?.data?.data?.document?.id);
      } else if (res?.data?.error_description?.statusCode == 401) {
        setLoader(false);
        toast.error(res?.data?.error_description?.message);
        localStorage.clear();
        navigate("/login");
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
    }
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setErrors({});
  };

  const handleChangeDoc = (e) => {
    setDocDetails({ ...docDetails, [e.target.name]: e.target.value });
    setErrors({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const obj = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        mobile: data?.mobileNumber,
      };
      const res = await editProfileApi(obj);
      if (res?.status == 200) {
        toast.success("Profile Updated Successfully");
        getProfile();
      } else if (res?.data?.error_description?.statusCode == 401) {
        setLoader(false);
        toast.error(res?.data?.error_description?.message);
        localStorage.clear();
        navigate("/login");
      } else {
        setLoader(false);
      }
    } catch (err) {}
    getProfile();
  };

  const handleAChange = (event) => {
    const file = event.target.files[0];
    const validImageTypes = /\.(jpg|jpeg|png)$/i;

    if (!validImageTypes.test(file.name)) {
      toast.error("Please select a valid image file (jpg, jpeg, png)");
      fileInputRef.current.value = ""; // Clear the input
    } else {
      setAdharUrl(file);
      setAPreview(URL.createObjectURL(file));
    }
  };
  const handleADocBackChange = (event) => {
    const file = event.target.files[0];
    const validImageTypes = /\.(jpg|jpeg|png)$/i;

    if (!validImageTypes.test(file.name)) {
      toast.error("Please select a valid image file (jpg, jpeg, png)");
      myAdharBackInputRef.current.value = ""; // Clear the input
    } else {
      setAdharUrlBack(file);
      setAPreviewBack(URL.createObjectURL(file));
    }
  };

  const handlePChange = (event) => {
    const file = event.target.files[0];
    const validImageTypes = /\.(jpg|jpeg|png)$/i;

    if (!validImageTypes.test(file.name)) {
      toast.error("Please select a valid image file (jpg, jpeg, png)");
      fileInputRef.current.value = ""; // Clear the input
    } else {
      setPanUrl(file);
      setPanPreview(URL.createObjectURL(file));
    }
  };

  const removeABImage = () => {
    setAdharUrlBack(null);
    setAPreviewBack(null);
    if (myAdharBackInputRef && myAdharBackInputRef?.value) {
      myAdharBackInputRef.current.value = ""; // Clear the input
    }
  };

  const removeAdharImage = () => {
    setAdharUrl(null);
    setAPreview(null);
    if (myAdharInputRef && myAdharInputRef?.value) {
      myAdharInputRef.current.value = ""; // Clear the input
    }
  };

  const removePanImage = () => {
    setPanUrl(null);
    setPanPreview(null);
    if (myPanInputRef && myPanInputRef?.value) {
      myPanInputRef.current.value = ""; // Clear the input
    }
  };

  const goToNominee = () => {
    navigate("/nominees-list");
  };

  const goToAccount = () => {
    navigate("/account-details");
  };
  const handleDocAdd = async () => {
    try {
      if (validateA()) {
        if (!aPreview) {
          toast.error("Aadhar Card Front Image is required");
          return;
        }
        if (!aPreviewBack) {
          toast.error("Aadhar Card Back Image is required");
          return;
        }
        if (!panPreview) {
          toast.error("Pan Card Image is required");
          return;
        }
        let res;
        if (userInfo?.docStatus == "rejected") {
          setLoading(true);
          res = await updateDocApi(docDetails, adharUrl, adharUrlBack, panUrl);
        } else {
          setLoading(true);
          res = await addDocApi(docDetails, adharUrl, adharUrlBack, panUrl);
        }
        if (res?.status == 200) {
          toast.success(res?.data?.message);
          setShowDoc(false);
          getProfile();
          setLoading(false);
          setPanPreview("");
          setAdharUrl("");
          setAdharUrlBack("");
          setPanUrl("");
          setAPreview("");
          setAPreviewBack("");
        } else if (res?.data?.error_description?.statusCode == 401) {
          toast.error(res?.data?.error_description?.message);
          localStorage.clear();
          setLoading(false);
          navigate("/login");
        } else {
          setLoading(false);
          toast.error(res?.data?.error_description?.message);
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleSubmitOTP = async () => {
    try {
      const res = await ResendOTPApi({ email: data?.email });
      if (res?.data) {
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
      }
    } catch (err) {
      throw err;
    }
  };

  const matchOtp = async () => {
    try {
      const res = await verifyOtpApi({
        email: data?.email,
        otp: otp,
      });
      if (res?.status == 200) {
        getProfile();
        setShowOTP(false);
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
      }
    } catch (err) {
      throw err;
    }
  };

  return (
    <>
      <div className="w-full h-auto flex flex-col justify-center py-5 align-center items-center">
        {loader ? (
          <Loader />
        ) : (
          <div className="border border-[#005555] w-[350px] md:w-[400px] lg:w-[600px] bg-white rounded-lg shadow">
            <h2 className="text-2xl text-black text-center w-full font-bold mt-4">
              Profile
            </h2>
            <form onSubmit={handleSubmit} className="w-full h-full px-8 py-8">
              <div className="flex flex-row justify-between">
                <div className="w-full flex flex-col text-md mb-2">
                  <label className="text-black text-sm mb-2">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={data.firstName}
                    onChange={handleChange}
                    className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
                    placeholder="First Name"
                  />
                  {errors.firstName && (
                    <p className="text-red-500 text-xs">{errors.firstName}</p>
                  )}
                </div>
                <div className="ml-2 w-full flex flex-col text-md mb-2">
                  <label className="text-black text-sm mb-2">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={data.lastName}
                    onChange={handleChange}
                    className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
                    placeholder="Last Name"
                  />
                  {errors.lastName && (
                    <p className="text-red-500 text-xs">{errors.lastName}</p>
                  )}
                </div>
              </div>
              <div className="flex">
                <div className="flex flex-col text-md mb-2 w-full">
                  <label className="text-black text-sm mb-2">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={data.email}
                    disabled
                    className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
                    placeholder="Email"
                  />
                  {errors.email && (
                    <p className="text-red-500 text-xs">{errors.email}</p>
                  )}
                </div>
                <div className="ml-2  mt-7">
                  {isEmailVerify ? (
                    <button
                      type="button"
                      disabled
                      onClick={() => {}}
                      className="whitespace-nowrap  border-2 border-green-600 px-4 py-1.5 text-green-600 rounded-md w-full"
                    >
                      Verifed
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={async () => {
                        try {
                          const res = await ResendOTPApi({
                            email: data?.email,
                          });
                          if (res?.data) {
                            setShowOTP(true);
                            toast.success(res?.data?.message);
                          } else {
                            toast.error(res?.data?.message);
                          }
                        } catch (err) {
                          throw err;
                        }
                      }}
                        className="whitespace-nowrap cursor-pointer bg-[#FA8072] px-2 py-2 text-white rounded-md w-full outlined animate-pulse zoom-effect text-white"
                    >
                      Verify Email
                    </button>
                  )}
                </div>
              </div>
              <div className="flex flex-col text-md mb-2">
                <label className="text-black text-sm mb-2">Mobile Number</label>
                <input
                  type="text"
                  name="mobileNumber"
                  value={data.mobileNumber}
                  onChange={handleChange}
                  className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
                  placeholder="Mobile Number"
                />
                {errors.mobileNumber && (
                  <p className="text-red-500 text-xs">{errors.mobileNumber}</p>
                )}
              </div>
              <div className="flex flex-row justify-between mt-6">
                <button
                  type="button"
                  onClick={(e) => {
                    setShowDoc(true);
                  }}
                  className={`ml-2 relative group overflow-hidden border-2 border-[#005555] rounded-lg px-4 py-2 w-full text-[#005555] font-semibold outlined
                    ${userInfo?.docStatus === 'not submit'
                      ? "animate-pulse zoom-effect bg-[#005555] text-white"
                      : ""
                  }`}
                >
                
                  {docId && userInfo?.docStatus !== "rejected"
                    ? "Document Details"
                    : docId && userInfo?.docStatus == "rejected"
                    ? "Update Your Documents"
                    : "Add Document"}
                </button>

                <button
                  type="button"
                  onClick={(e) => {
                    goToNominee();
                  }}
                  className={`ml-2 relative group overflow-hidden border-2 border-[#005555] rounded-lg px-4 py-2 w-full text-[#005555] font-semibold outlined
                    ${userInfo?.nomineeStatus === 'not submit'
                      ? "animate-pulse zoom-effect bg-[#005555] text-white"
                      : ""
                  }`}
                  
                >
                  Nominee Details
                </button>
                <button
                  type="button"
                  onClick={(e) => {
                    goToAccount();
                  }}
                  className={`ml-2 relative group overflow-hidden border-2 border-[#005555] rounded-lg px-4 py-2 w-full text-[#005555] font-semibold outlined
                    ${userInfo?.bankAccountStatus === 'not submit'
                      ? "animate-pulse zoom-effect bg-[#005555] text-white"
                      : ""
                  }`}
                 
                >
                  <span className="absolute inset-0 bg-white opacity-10 group-hover:opacity-20 transition-opacity duration-300"></span>
                  Account Details
                </button>
              </div>

              <div className="w-full flex item-center justify-center mt-6">
                <button
                  type="submit"
                  className="cursor-pointer bg-[#005555] px-2 py-2 text-white rounded-md w-full"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
      <AddYourDocModal show={showDoc} onClose={handleDoxClose}>
        {loading ? <Loader /> : ""}
        <p className="w-full text-center text-xl text-[#005555] font-bold">
          {docId && userInfo?.docStatus !== "rejected"
            ? "Your Documents"
            : docId && userInfo?.docStatus == "rejected"
            ? "Update Your Documents"
            : "Add Your Documents"}
        </p>
        <div className="flex flex-col text-md mb-2">
          <label className="text-black text-sm mb-2">Aadhar Card No.</label>
          <input
            type="text"
            name="aadharcardNo"
            disabled={
              docId && userInfo?.docStatus !== "rejected" ? true : false
            }
            value={
              docId && userInfo?.docStatus !== "rejected"
                ? docDetails?.aadharcardNo.substring(0, 2) +
                  docDetails?.aadharcardNo
                    .substring(2, docDetails?.aadharcardNo.length - 2)
                    .replace(/./g, "*") +
                  docDetails?.aadharcardNo.substring(
                    docDetails?.aadharcardNo.length - 2,
                    docDetails?.aadharcardNo.length
                  )
                : docDetails?.aadharcardNo
            }
            onChange={handleChangeDoc}
            className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
            placeholder="**** **** ****"
          />
          {errorDoc.aadharcardNo && (
            <p className="text-red-500 text-sm mt-1">{errorDoc.aadharcardNo}</p>
          )}
        </div>
        <div className="flex flex-col text-md mb-2">
          <label className="text-black text-sm mb-2">PAN Card No.</label>
          <input
            type="text"
            name="panCardNo"
            disabled={
              docId && userInfo?.docStatus !== "rejected" ? true : false
            }
            value={
              docId && userInfo?.docStatus !== "rejected"
                ? docDetails?.panCardNo.substring(0, 2) +
                  docDetails?.panCardNo
                    .substring(2, docDetails?.panCardNo.length - 2)
                    .replace(/./g, "*") +
                  docDetails?.panCardNo.substring(
                    docDetails?.panCardNo.length - 2,
                    docDetails?.panCardNo.length
                  )
                : docDetails?.panCardNo
            }
            onChange={handleChangeDoc}
            className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
            placeholder="ABCDE1234F"
          />
          {errorDoc.panCardNo && (
            <p className="text-red-500 text-sm mt-1">{errorDoc.panCardNo}</p>
          )}
        </div>
        {docId && userInfo?.docStatus !== "rejected" ? (
          ""
        ) : (
          <>
            <label className="text-black text-sm mb-2 w-full">
              Upload Aadhar Card
            </label>
            <div className="flex justify-between w-full">
              <div className="flex flex-col text-md mb-2 w-full">
                <div
                  onClick={() => {
                    if (aPreview) {
                    } else {
                      handleAClick();
                    }
                  }}
                  className="w-full h-20 border-2 border-dashed border-gray-300 rounded-md flex flex-col items-center justify-center cursor-pointer"
                >
                  {aPreview ? (
                    <div className="relative w-32  h-20  mt-2 mb-2">
                      <img
                        src={aPreview}
                        alt="Nominee"
                        className="w-full h-20 mt-1 rounded-md"
                      />

                      <FontAwesomeIcon
                        icon={faTimes}
                        className="absolute bg-[#005555] rounded-full top-0 -right-2 text-white px-1 py-0.5 cursor-pointer"
                        onClick={removeAdharImage}
                      />
                    </div>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faUpload} className="mt-4 h-6" />
                      <p className="text-gray-400">Click to upload</p>
                      <input
                        type="file"
                        name="nomineePicture"
                        ref={myAdharInputRef}
                        className="hidden"
                        accept="image/*"
                        onChange={handleAChange}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="w-full flex flex-col text-md mb-2 ml-2">
                <div
                  className="w-full h-20 border-2 border-dashed border-gray-300 rounded-md flex flex-col items-center justify-center cursor-pointer"
                  onClick={() => {
                    if (aPreviewBack) {
                    } else {
                      handleFileBAdharClick();
                    }
                  }}
                >
                  {aPreviewBack ? (
                    <div className="relative w-32  h-20  mt-2 mb-2">
                      <img
                        src={aPreviewBack}
                        alt="Nominee"
                        className="w-full h-20 mt-1 rounded-md"
                      />

                      <FontAwesomeIcon
                        icon={faTimes}
                        className="absolute bg-[#005555] rounded-full top-0 -right-2 text-white px-1 py-0.5 cursor-pointer"
                        onClick={removeABImage}
                      />
                    </div>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faUpload} className="mt-4 h-6" />
                      <p className="text-gray-400 mt-1">Upload Back Pic</p>
                      <input
                        type="file"
                        name="nomineePicture"
                        ref={myAdharBackInputRef}
                        className="hidden"
                        accept="image/*"
                        onChange={handleADocBackChange}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {docId && userInfo?.docStatus !== "rejected" ? (
          ""
        ) : (
          <div className="flex flex-col ml-2 text-md mb-2 w-full">
            <label className="text-black text-sm mb-2">Upload PAN Card</label>
            <div
              onClick={() => {
                if (panPreview) {
                } else {
                  handlePClick();
                }
              }}
              className="w-full h-20 border-2 border-dashed border-gray-300 rounded-md flex flex-col items-center justify-center cursor-pointer"
            >
              {panPreview ? (
                <div className="relative w-32  h-20  mt-2 mb-2">
                  <img
                    src={panPreview}
                    alt="Nominee"
                    className="w-full h-20 mt-1 rounded-md"
                  />

                  <FontAwesomeIcon
                    icon={faTimes}
                    className="absolute bg-[#005555] rounded-full top-0 -right-2 text-white px-1 py-0.5 cursor-pointer"
                    onClick={removePanImage}
                  />
                </div>
              ) : (
                <>
                  <FontAwesomeIcon icon={faUpload} className="mt-4 h-6" />
                  <p className="text-gray-400">Click to upload</p>
                  <input
                    type="file"
                    name="nomineePicture"
                    ref={myPanInputRef}
                    className="hidden"
                    accept="image/*"
                    onChange={handlePChange}
                  />
                </>
              )}
            </div>
          </div>
        )}
        {docId && userInfo?.docStatus !== "rejected" ? (
          ""
        ) : (
          <button
            type="button"
            onClick={handleDocAdd}
            className="mt-4 cursor-pointer bg-[#005555] px-2 py-2 text-white rounded-md w-full"
          >
            Submit
          </button>
        )}
      </AddYourDocModal>
      <VerifyEmailModal show={showOTP} onClose={handleOTPClose}>
        <h2 className="text-2xl text-black text-center w-full font-bold mt-4">
          Account validation
        </h2>
        <p className="text-center font-samibold mt-6">
          Please enter the 6 digit code we send to <b>{data?.email}</b>
        </p>

        <form className="mt-6">
          <div className="flex  w-full justify-center  px-4 mb-3 align-center items-center">
            <OTPInput
              value={otp}
              onChange={setOtp}
              inputClassName="otp-input border"
              className="otp-input "
              autoFocus
              OTPLength={4}
              otpType="number"
              disabled={false}
              secure
            />
          </div>

          <div className="flex flex-col px-4">
            <p className="text-[#005555] self-end">Didn't get a code?</p>
            <ResendOTP
              className="cursor-pointer"
              onResendClick={(e) => handleSubmitOTP(e, false)}
            />
          </div>
          <div className="px-4 mb-4 w-full flex item-center justify-center mt-8">
            <button
              type="button"
              onClick={matchOtp}
              className="bg-[#005555] px-2 py-2 text-white rounded-md w-full"
            >
              Verify Otp
            </button>
          </div>
        </form>
      </VerifyEmailModal>
    </>
  );
};
export default ProfilePage;
