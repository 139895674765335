import React, { useEffect, useRef, useState } from "react";
import toast from "toastr";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { getCustomerBank, addCustomerBankApi, deleteCustomerBankByID } from "../../api";
import { getUserNominee, addNomineApi, deleteNomineeByID } from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faTrash,
  faTimes,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import AddAccountModal from "../../components/Modals/addAccountModal";

const AccountDetails = () => {
  toast.options = { preventDuplicates: true };
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [accountList, setAccountList] = useState([]);
  const [showAccount, setShowAccount] = useState(false);
  const fileInputRef = useRef(null);
  const [accountDetail, setAccountDetail] = useState({
    customerName: "",
    bankName: "",
    bankIfsc: "",
    bankNumber: "",
  });
  const [imageUrl, setImageUrl] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [errorAccount, setErrorAccount] = useState({});
  useEffect(() => {
    fetchAccount();
  }, []);

  const fetchAccount = async () => {
    try {
      setLoader(true);
      const res = await getCustomerBank();
      if (res?.status == 200) {
        console.log("[22] =>", res?.data);
        setLoader(false);
        setAccountList(res?.data);
      } else if (res?.data?.error_description?.statusCode === 401) {
        toast.error(res?.data?.error_description?.message);
        setLoader(false);
      } else {
        setLoader(false);
        console.error("Failed to fetch data:", res);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleChangeAccount = (e) => {
    setAccountDetail({ ...accountDetail, [e.target.name]: e.target.value });
    console.log('[55] =>', accountDetail)
    setErrors({});
  };

  const handleAccountClose = () => {
    setShowAccount(false);
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const validImageTypes = /\.(jpg|jpeg|png)$/i;

    if (!validImageTypes.test(file.name)) {
      toast.error("Please select a valid image file (jpg, jpeg, png)");
      fileInputRef.current.value = ""; // Clear the input
    } else {
      setImageUrl(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const removeImage = () => {
    setImagePreview(null);
    setImageUrl(null);
    if (fileInputRef && fileInputRef?.value) {
      fileInputRef.current.value = ""; // Clear the input
    }
  };


  const validate = () => {
    const cifPattern = /^\d{11}$/; // Matches exactly 11 digits for IFSC No.
    const accountNumPattern = /^\d{9,18}$/; // Matches 9-18 digits for Account No.
    let newErrors = {};
    if (!accountDetail.customerName || accountDetail.customerName == '') {
        newErrors.customerName = "Name is required";
    }  else if (!accountDetail.bankName || accountDetail.bankName == '') {
        newErrors.bankName = "Bank Name is required";
    } else if (!accountDetail.bankIfsc || accountDetail.bankIfsc == '') {
        newErrors.bankIfsc = "IFSC No. is required";
    } else if (!cifPattern.test(accountDetail.bankIfsc)) {
        newErrors.bankIfsc = "IFSC No. should be exactly 11 digits";
    } else if (!accountDetail.bankNumber || accountDetail.bankNumber == '') {
        newErrors.bankNumber = "Account No. is required";
    } else if (!accountNumPattern.test(accountDetail.bankNumber)) {
        newErrors.bankNumber = "Account No. should be 9 to 18 digits";
    }
    return newErrors;
};


  const handleAddAccount = async (e) => {
    e.preventDefault();
    try {
      if (validate()) {
        // if (!imagePreview) {
        //   toast.error("CheckBook Image is required");
        //   return;
        // }
        setLoader(true);
        const res = await addCustomerBankApi(accountDetail, imageUrl);
        if (res?.status == 200) {
          toast.success(res?.data?.message);
          setAccountDetail({});
          setShowAccount(false);
          setLoader(false);
          fetchAccount();
          removeImage();
        } else if (res?.data?.error_description?.statusCode == 401) {
          setAccountDetail({});
          setLoader(false);
          toast.error(res?.data?.error_description?.message);
          localStorage.clear();
          navigate("/login");
        } else {
          setAccountDetail({});
          setLoader(false);
          toast.error(res?.data?.error_description?.message);
        }
      }
    } catch (err) {
      setAccountDetail({});
      setLoader(false);
    }
  };

  const deleteAccount = async (id) => {
    try {
      setLoader(true);
      const res = await deleteCustomerBankByID(id);
      if (res?.status == 200) {
        setLoader(false);
        fetchAccount();
      } else if (res?.data?.error_description?.statusCode == 401) {
        toast.error(res?.data?.error_description?.message);
        setLoader(false);
      } else {
        setLoader(false);
        console.error("Failed to fetch data:", res);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="w-full flex justify-between items-center mb-6">
          <p
            className="font-bold text-primary text-xl mb-4 cursor-pointer "
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Bank Account Details
          </p>
          <button
            className="text-white bg-[#005555] px-4 py-2 rounded"
            onClick={(e) => {
              setShowAccount(true);
            }}
          >
            Add Bank
          </button>
        </div>

        {loader ? (
          <Loader />
        ) : (
          <>
          {!loader &&!accountList.length && (
              <div className="col-12 text-center">No record found.</div>)}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full">
              {accountList.map((item, index) => (
                <div
                  key={index}
                  className="relative border-2 border-dashed border-[#005555] text-[#005555] rounded-lg p-4 shadow-md transform transition-transform duration-300 hover:bg-[#F1F3F8]"
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="absolute top-2 right-2 text-gray-500 hover:text-red-600 cursor-pointer"
                    onClick={() => deleteAccount(item.id)} // Handle delete
                  />

                  <h2 className="text-xl font-semibold mb-2">{item.customerName}</h2>
                  <p>
                    <strong>Bank Name:</strong> {item.bankName}
                  </p>
                  <p>
                    <strong>IFSC No:</strong> {item.bankIfsc}
                  </p>
                  <p>
                    <strong>Account No:</strong> {item.bankNumber}
                  </p>
                  <p>
                    <strong>Created At:</strong>{" "}
                    {new Date(item.createdAt).toLocaleDateString()}
                  </p>

                  {/* Verification Status Chip */}
                  <div
                    className={`mt-2 inline-block px-2 py-1 text-sm font-semibold text-capitalize rounded ${
                      item.verify
                        ? "bg-green-100 text-green-700"
                        : "bg-red-100 text-red-700"
                    }`}
                  >
                    {item.status}
                  </div>
                  {item.reason && item.status === "rejected" && (
                    <p>
                      <strong>Reason:</strong> {item.reason}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <AddAccountModal show={showAccount} onClose={handleAccountClose}>
        {loader ? <Loader /> : ""}
        <p className="w-full text-center text-xl text-[#005555] font-bold">
          Add Bank Account
        </p>
        <form className="w-full h-full px-2 py-0" onSubmit={handleAddAccount}>
          <div className="flex flex-col text-md mb-2">
            <label className="text-black text-sm mb-2">Your Name</label>
            <input
              type="text"
              name="customerName"
              value={accountDetail.customerName}
              onChange={handleChangeAccount}
              className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
              placeholder="Customer Name"
            />
            {errorAccount.customerName && (
              <p className="text-red-500 text-sm mt-1">{errorAccount.customerName}</p>
            )}
          </div>
          <div className="flex flex-col text-md mb-2">
            <label className="text-black text-sm mb-2">Bank Name</label>
            <input
              type="text"
              name="bankName"
              value={accountDetail.bankName}
              onChange={handleChangeAccount}
              className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
              placeholder="e.g. SBI, HDFC"
            />
            {errorAccount.bankName && (
              <p className="text-red-500 text-sm mt-1">{errorAccount.bankName}</p>
            )}
          </div>
          <div className="flex flex-col text-md mb-2">
            <label className="text-black text-sm mb-2">Account No.</label>
            <input
              type="text"
              name="bankNumber"
              onChange={handleChangeAccount}
              value={accountDetail?.bankNumber}
              maxLength={20}
              className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
              placeholder="**** **** ****"
            />
            {errorAccount.bankNumber && (
              <p className="text-red-500 text-sm mt-1">
                {errorAccount.bankNumber}
              </p>
            )}
          </div>
          <div className="flex flex-col text-md mb-2">
            <label className="text-black text-sm mb-2">IFSC Code</label>
            <input
              type="text"
              name="bankIfsc"
              value={accountDetail.bankIfsc}
              onChange={handleChangeAccount}
              className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
              placeholder="IFSC Code"
            />
            {errorAccount.bankIfsc && (
              <p className="text-red-500 text-sm mt-1">{errorAccount.bankIfsc}</p>
            )}
          </div>
        

          <>
            <label className="text-black text-sm mb-2">
              Upload CheckBook Image
            </label>
            <div className="flex w-full">
              <div className="w-full flex flex-col text-md mb-2">
                <div
                  className="w-full h-20 border-2 border-dashed border-gray-300 rounded-md flex flex-col items-center justify-center cursor-pointer"
                  onClick={() => {
                    if (imagePreview) {
                    } else {
                      handleFileClick();
                    }
                  }}
                >
                  {imagePreview ? (
                    <div className="relative w-32  h-20  mt-2 mb-2">
                      <img
                        src={imagePreview}
                        alt="Nominee"
                        className="w-full h-20 mt-1 rounded-md"
                      />

                      <FontAwesomeIcon
                        icon={faTimes}
                        className="absolute bg-[#005555] rounded-full top-0 -right-2 text-white px-1 py-0.5 cursor-pointer"
                        onClick={removeImage}
                      />
                    </div>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faUpload} className="mt-4 h-6" />
                      <p className="text-gray-400 mt-1">Upload CheckBook Pic</p>
                      <input
                        type="file"
                        name="nomineePicture"
                        ref={fileInputRef}
                        className="hidden"
                        accept="image/*"
                        onChange={handleFileChange}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </>

          <button
            type="submit"
            className="mt-4  cursor-pointer bg-[#005555] px-2 py-2 text-white rounded-md w-full"
          >
            Submit
          </button>
        </form>
      </AddAccountModal>
    </>
  );
};

export default AccountDetails;
