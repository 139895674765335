import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileWord, faFileExcel, faFileImage, faFileAlt, faDownload } from "@fortawesome/free-solid-svg-icons";

export const formatDate = (
    dateString,
) => {
    if (!dateString) return '-'
    const date = new Date(dateString);

    // Extract day, month, and year
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear(); // Get full year

    // Format the date in DD-MM-YYYY format
    return `${day}-${month}-${year}`;

};


export const currancyFormat = (number = 0) => number.toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  

export  const getFileIcon = (filename) => {
    const ext = filename.split(".").pop().toLowerCase();
    switch (ext) {
      case "pdf":
        return <FontAwesomeIcon icon={faFilePdf} className="text-red-500 w-8 h-8" />;
      case "doc":
      case "docx":
        return <FontAwesomeIcon icon={faFileWord} className="text-blue-500 w-8 h-8" />;
      case "xls":
      case "xlsx":
        return <FontAwesomeIcon icon={faFileExcel} className="text-green-500 w-8 h-8" />;
      case "png":
      case "jpg":
      case "jpeg":
        return <FontAwesomeIcon icon={faFileImage} className="text-yellow-500 w-8 h-8" />;
      default:
        return <FontAwesomeIcon icon={faFileAlt} className="text-gray-500 w-8 h-8" />;
    }
  };