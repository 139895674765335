import React, { useEffect, useState } from "react";
import { getPurchasePropertyApi } from "../../api";
import ReactPaginate from "react-paginate";
import toast from "toastr";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { formatDate, currancyFormat } from '../../utils/index'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

const settings = {
  dots: false,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

const PurchasedPage = () => {
  toast.options = { preventDuplicates: true };
  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1); // Current page index
  const itemsPerPage = 10; // Number of items per page
  const [loader, setLoader] = useState(false);
  const [totalRentEarned, setTotalRentEarned] = useState(0);

  useEffect(() => {
    fetchProperties(1);
  }, []);

  const fetchProperties = async (pageNumber) => {
    try {
      setLoader(true);
      const response = await getPurchasePropertyApi({ page: pageNumber }); // API pagination usually starts from 1
      if (response?.status == 200) {
        setLoader(false);
        setProperties(response.data?.data); // Set fetched properties
        setTotalCount(response.data?.count); // Set total number of pages
        setTotalRentEarned(response.data.totalRentEarned)
      } else if (response?.data?.error_description?.statusCode == 401) {
        toast.error(response?.data?.error_description?.message);
        setLoader(false);
        localStorage.clear();
        navigate("/login");
      } else {
        setLoader(false);
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  const handlePageClick = (selectedPage) => {
    fetchProperties(selectedPage.selected + 1);
    setCurrentPage(selectedPage.selected + 1);
  };

  const pageCount = Math.ceil(totalCount / itemsPerPage);

  const aggregateProperties = (properties) => {
    return properties.reduce(
      (acc, { totalAmount, rentEarned, totalAppreciation }) => ({
        amount: acc.amount + totalAmount,
        rentEarned: totalRentEarned,
        appreciation: acc.appreciation + totalAppreciation,
      }),
      { amount: 0, rentEarned: 0, appreciation: 0 }
    );
  };

  const aggregatedData = aggregateProperties(properties);

  const data = [
    { name: "Investment", y: aggregatedData.amount, color: "#E38627" },
    { name: "Rent Earned", y: aggregatedData.rentEarned, color: "#C13C37" },
    { name: "Appreciation", y: aggregatedData.appreciation, color: "#6A2135" },
  ];

  const chartOptions = {
    chart: {
      type: "pie",
      backgroundColor: "#fafafa",
    },
    title: {
      text: "Investment Overview",
    },
    series: [
      {
        name: "Amount",
        data: data,
        showInLegend: true,
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.percentage:.1f} %",
        },
      },
    ],
  };

  return (

    <div className="section-properties container">
    
          {/* Top Navigation Section */}
          <div className="w-full flex justify-between items-center">
          <p
            className="font-bold text-primary text-xl my-4 cursor-pointer "
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Purchased Properties
          </p>
          {/* <button
            className="text-white bg-[#005555] px-4 py-2 rounded"
            onClick={(e) => {
              setShowNominee(true);
            }}
          >
            Add Nominee 
          </button> */}
        </div>
      {loader ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-sm-8 col-12">
            <div className="row gap-8">
              { properties && properties.length > 0 ? (
                properties.map((val) => (
                  <div
                    key={val.id}
                    className="col-xs-12 col-5 shadow-md rounded-lg overflow-hidden bg-white p-0"
                  >
                    <div className="property-item">
                      {val?.property?.images?.length <= 1 ? (
                        <a href={`/property-details?id=${val?.property?.id}`}>
                          <img
                            src={val?.property?.images?.[0]}
                            alt="Image"
                            className="h-[280px] object-fit w-full"
                          />
                        </a>
                      ) : (
                        <Slider {...settings}>
                          {val?.property?.images.map((val1) => {
                            return (
                              <a key={val1.id}
                                href={`/property-details?id=${val?.property?.id}`}
                                className="img"
                              >
                                <img
                                  src={val1}
                                  alt="Image"
                                  className="h-[280px] object-fit w-full"
                                />
                              </a>
                            );
                          })}
                        </Slider>
                      )}

                      <div className="property-content p-3">
                        <h5 className="color=[#005555]">{val.property?.name}</h5>
                        <div className="flex flex-col">
                          <span className="font-bold mb-1 text-black">
                            Purchased Brics : {val?.totalBrics}
                          </span>
                          <span className="font-bold mb-2 text-black">
                            Amount : {currancyFormat(val?.totalAmount)}
                          </span>
                          {/* <span className="font-bold mb-2 text-black">
                            Purchased Date : {formatDate(val?.createdAt)}
                          </span> */}
                        </div>

                        <a
                          href={`/purchased-transaction?id=${val?.property?.id}`}
                          className="btn btn-primary py-2 px-3 rounded-0 my-6"
                        >
                          See details
                        </a>

                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-12 text-center">No properties found.</div>
              )}

              <ReactPaginate
                breakLabel="..."
                breakClassName="break-me"
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                className={"px-2 flex justify-center pt-12 font-bold"}
                pageClassName={
                  "ml-2 mr-2  px-2.5 py-1 text-sm rounded-full font-bold"
                }
                onPageChange={handlePageClick}
                containerClassName="pagination"
                activeClassName="active bg-[#005555] text-white px-2.5 py-1 rounded-full"
                previousClassName="hidden"
                nextClassName="hidden"
              />

            </div>
          </div>
          <div className="col-sm-4 col-12">
            {properties && properties.length > 0 && (
              <div
                className="property-chart relative text-center"
                style={{
                  border: "1px solid #ddd",
                  padding: "40px 30px",
                  display: "inline-block",
                  position: "relative",
                  width: "100%",
                  maxWidth: "500px",
                  margin: "0 auto",
                  backgroundColor: "#fafafa",
                  borderRadius: "10px",
                }}
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "300px",
                    margin: "80px auto",
                  }}
                />
              </div>
            )}

          </div>
        </div>
      )}


    </div>

  );
};

export default PurchasedPage;
