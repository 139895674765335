import React, { useEffect, useState } from "react";
import { getPropertyApi } from "../../api";
import ReactPaginate from "react-paginate";
import toast from "toastr";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "../../components/Loader";
import { formatDate, currancyFormat } from '../../utils/index'


const settings = {
  dots: false,
  infinite: false,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

const PropertyPage = () => {
  toast.options = { preventDuplicates: true };
  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1); // Current page index
  const itemsPerPage = 10; // Number of items per page
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    fetchProperties(1);
  }, []);

  const fetchProperties = async (pageNumber) => {
    try {
      setLoader(true);
      const response = await getPropertyApi({ page: pageNumber }); // API pagination usually starts from 1
      if (response?.status == 200) {
        setLoader(false);
        setProperties(response.data?.data); // Set fetched properties
        setTotalCount(response.data?.count); // Set total number of pages
      } else if (response?.data?.error_description?.statusCode == 401) {
        toast.error(response?.data?.error_description?.message);
        setLoader(false);
        localStorage.clear();
        navigate("/login");
      } else {
        setLoader(false);
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  const handlePageClick = (selectedPage) => {
    fetchProperties(selectedPage.selected + 1);
    setCurrentPage(selectedPage.selected + 1);
  };

  const pageCount = Math.ceil(totalCount / itemsPerPage);


  return (

    <section>
      <p className="text-3xl text-primary font-bold text-center w-full mb-4">
        Properties
      </p>
      <div className="container">
        <div className="row">
          {!loader && properties && properties.length > 0 ? (
            properties.map((val) => (
              <div key={val.id} className="col-xs-12 col-sm-6 col-md-6 col-lg-4 mb-4">
                <div className="features-1 rounded-lg p-6">
                  {/* <span className="flaticon-house"></span> */}
                  <Slider {...settings}>
                    {val?.images.map((val1) => {
                      return ( 
                        <a
                          href={`/property-details?id=${val?.id}`}
                          className="img"
                        >
                          <img
                            src={val1}
                            alt="Image"
                            className="h-[240px] object-cover w-full rounded-lg"
                          />
                        </a>
                      );
                    })}
                  </Slider>
                  <div className="text-start">
                    <p className="property-title mt-4">{val.name}</p>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <span>Each Bric Cost</span>
                        <p className="text-primary font-bold">{currancyFormat(val.eachBricCost)}</p>
                      </div>
                      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <span>Launch Date</span>
                        <p className="text-primary font-bold">{formatDate(val.launchingDate)}</p>
                      </div>
                      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <span>Total Brics</span>
                        <p className="text-primary font-bold">{val.totalBrics}</p>
                      </div>
                      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <span>Rent paid</span>
                        <p className="text-primary font-bold">{val.rentPerMonth}</p>
                      </div>
                      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <span>Available</span>
                        <p className="text-primary font-bold">{val.availableBrics}</p>
                      </div>
                      
                      {/* Add Status Badge */}
                      <div className="property-status col-xs-12 col-sm-6 col-md-6 col-lg-6">
                      <span>Status</span>  <p className="text-primary font-bold">
                        {val.availableBrics === 0 ? (
                          <span className="badge bg-danger">Sold Out</span>
                        ) : new Date(val.launchingDate) > new Date() ? (
                          <span className="badge bg-warning">Coming Soon</span>
                        ) : (
                          <span className="badge bg-success">Available</span>
                        )}</p>
                      </div>
                    </div>
                       {/* Status location */}
                    {val.location &&(
                    <p>
                      { val.location.length > 30
                        ? val.location.substring(0, 30) + "..."
                        : val.location}
                    </p>)}
                    <span>
                      {val && val.aboutLocation && val.aboutLocation.length > 50
                        ? val.aboutLocation.substring(0, 50) + "..."
                        : val.aboutLocation}

                    </span>
                  </div>

                  <a
                    href={`/property-details?id=${val?.id}`}
                    className="learn-more"
                  >
                    See details
                  </a>

                </div>
              </div>
            ))
          ) : loader ? (
            <Loader />
          ) : (
            <div className="col-12 text-center">No properties found.</div>
          )}
        </div>
        <ReactPaginate
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          className={"px-2 flex justify-center mt-6 font-bold"}
          pageClassName={
            "ml-2 mr-2  px-2.5 py-1 text-sm rounded-full font-bold"
          }
          onPageChange={handlePageClick}
          containerClassName="pagination"
          activeClassName="active bg-[#005555] text-white px-2.5 py-1 rounded-full"
          previousClassName="hidden"
          nextClassName="hidden"
        />
      </div>
    </section>

  );
};

export default PropertyPage;
